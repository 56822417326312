<template>
  <v-col class="text-left" cols="12">
    <v-card class="mx-auto mb-2">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="headline">{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-card-text v-if="item.body_formating" v-html="item.body_formating"></v-card-text>

      <v-card-actions>
        <!-- <v-btn text color="deep-purple accent-4">Read</v-btn>
        <v-btn text color="deep-purple accent-4">Edit</v-btn>-->
        <v-spacer></v-spacer>
        <v-btn :to="{name: 'CabinetSerieEdit', params: {id: item.id}}" icon>
          <v-icon>$vuetify.icons.square_edit_outline</v-icon>
        </v-btn>
        <v-btn @click.stop="deleteSerie" icon>
          <v-icon>$vuetify.icons.delete</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>
<script>
export default {
  props: ["item", "index"],
  data() {
    return {};
  },
  methods: {
    deleteSerie() {
      this.$emit("remove-serie-modal", [this.item.id, this.index]);
    }
  }
};
</script>
