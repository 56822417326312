<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn :to="{name: 'CabinetIndex'}" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>Циклы</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn :to="{name: 'CabinetSerieAdd'}" text tile>
        Добавить
      </v-btn>
    </v-toolbar>

    <v-container>
      <v-btn color="primary" :to="{name: 'CabinetSerieAdd'}" dark fixed bottom right fab>
        <v-icon>$vuetify.icons.plus</v-icon>
      </v-btn>
      <v-row align="center">
          <no-content
          v-if="!loadPage && series.length == 0"
          route-name="CabinetSerieAdd"
          title="У вас еще нет циклов"
        ></no-content>
        <serie-card
          v-for="(item, index) in series"
          :key="item.id"
          :item="item"
          :index="index"
          @remove-serie-modal="deleteSerieModal"
        ></serie-card>
      </v-row>
      <v-dialog v-model="dialogDeleteSerie" class="text-center" max-width="290">
        <v-card>
          <v-card-title class="headline justify-center">Вы уверены?</v-card-title>

          <v-card-text>Что хотите удалить данную запись.</v-card-text>

          <v-card-actions class="justify-space-around">
            <v-btn color="red darken-1" text @click="deleteSerie">Да</v-btn>

            <v-btn color="green darken-1" text @click="dialogDeleteSerie = false">Нет</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SerieCard from "./SerieCard.vue";

export default {
  components: {
    SerieCard
  },
  data: () => ({
    dialogDeleteSerie: false,
    deleteData: null,
  }),
  methods: {
    getSeries() {
      this.$store
        .dispatch("cabinet_series/getSeries");
      // axios
      //   .get("/series-my/")
      //   .then(function(resp) {
      //     app.loading = false;
      //     if (resp.data.success == 1) {
      //       app.series = resp.data.series;
      //     }
      //   })
      //   .catch(function(error) {
      //     app.loading = false;
      //   });
    },
    deleteSerie() {
      let app = this;
      app.dialogDeleteSerie = false;

      this.$store
        .dispatch("cabinet_series/dellSerie", {
          deleteData: app.deleteData,
        });
      // axios
      //   .delete("/serie/" + app.deleteData[0])
      //   .then(function(resp) {
      //     console.log(resp.data);
      //     if (resp.data.success == 1) {
      //       bus.$emit("show-modal-error", [true, resp.data, "success"]);
      //       Vue.delete(app.series, app.deleteData[1]);
      //       app.dialogDeleteSerie = false;
      //     }
      //   })
      //   .catch(function(error) {
      //     bus.$emit("show-modal-error", [true, error.response.data, "error"]);
      //   });

    },
    deleteSerieModal(data) {
      this.deleteData = data;
      this.dialogDeleteSerie = true;
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      series: "cabinet_series/series"
    })
  },
  mounted() {
    this.getSeries();
  }
};
</script>
